// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awair-js": () => import("./../../../src/pages/AWAIR.js" /* webpackChunkName: "component---src-pages-awair-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pooler-js": () => import("./../../../src/pages/Pooler.js" /* webpackChunkName: "component---src-pages-pooler-js" */),
  "component---src-pages-subtle-js": () => import("./../../../src/pages/Subtle.js" /* webpackChunkName: "component---src-pages-subtle-js" */)
}

